.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  /* padding-left: 270px; */
  z-index: 99999;
}

.modalContainer {
  width: 80rem;
  height: auto;
  max-height: 90vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 20px;
  position: relative;
}
.user-modalContainer {
  min-width: 450px;
  width: fit-content;
  height: auto;
  max-height: 90vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 20px;
  position: relative;
}
.modalContainer .title {
  display: inline-block;
  text-align: center;
  /* margin-top: 10px; */
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.modalHeader {
  position: relative;
  text-align: center;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  align-items: baseline;
}

.modalContainer .body {
  flex: 50%;
  /* display: flex; */
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  /* font-size: 1.7rem; */
  /* text-align: center; */
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.capitalizeLetter::first-letter {
   text-transform: uppercase;
}

.capitalizeWords {
  text-transform: capitalize;
}
