.react-calendar {
  border-radius: 15px;

  border: 1px solid rgb(62, 62, 62, 0.1) !important;
  box-shadow: 13px 17px 26px -8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 13px 17px 26px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 13px 17px 26px -8px rgba(0, 0, 0, 0.2);
  .react-calendar__tile--now {
    background: #76fffc;
    border-radius: 15px;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
    border-radius: 15px;
  }
  .react-calendar__tile:hover {
    border-radius: 15px;
  }
  abbr {
        font-size: 14px !important;
        font-weight: 500;
      }
  .react-calendar__navigation {
    button {
      color: white;
      font-size: 18px;
      background-color: #006edc;
      
      &:first-child {
        border-top-left-radius: 15px;
      }
      &:last-child {
        border-top-right-radius: 15px;
      }
      &:hover {
        background-color: #767676 !important;
      }
    }
  }
}
